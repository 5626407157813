.status_no {
  color: red;
  font-weight: bold;
}

.status_ok {
  color: green;
  font-weight: bold;
}

.debug_mode {
  white-space: nowrap;
  color: orange;
  font-weight: bold;
  padding-right: 5px;
  padding-left: 10px;
}

.bug_icon {
  color: orange;
  font-weight: bold;
}

.license_tooltip {
  width: 350px;
  min-width: 350px;
}

.license_tooltip_content td {
  padding-left: 10px;
  text-align: right;
}

.app_info_text {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 0px 5px;
}

.app_info_text_container {
  display: flex;
}
