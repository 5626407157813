.zoom_toolbar {
  --checked-tool-color: #757575;
  --panel-color: #a2a2a2;
  --shadow: 2px 2px 5px rgba(154, 147, 140, 0.5), 5px 1px 5px rgb(35, 35, 35);
  --border-right: none;
  --border-left: none;
  --button-icon-size: 18px;

  display: flex;
  position: absolute;
  background: #848484;
  border: 4px double #848484;
  border-radius: 8px;
  overflow: hidden;
  right: 10px;
  top: 40%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.zoom_toolbar > div {
  border: none;
}
