.toolbar {
  --button-icon-size: 20px;
  --button-opacity: 1;
  --button-opacity-inactive: 1;
}

.inactiveToolbar {
  --border-right: 4px solid #676969;
  --border-left: 1px solid #676969;
  --shadow: 2px 2px 5px #2c2e2e, 5px 1px 2px #cbcbcb;
  --button-inactive-color: gray;
  --button-checked-icon-color: #4e4e4e;
}

.inactiveToolbar * {
  fill: #d9d9d9;
}

.checked {
  --button-opacity: 1;
}

.checked svg {
  font-size: calc(var(--button-icon-size) - 2px);
  height: calc(var(--button-icon-size) - 2px);
  width: calc(var(--button-icon-size) - 2px);
}

.checked * {
  fill: var(--button-checked-icon-color, rgb(226, 255, 190));
}

.active_tool {
  border-bottom: 5px solid white;
}

.usual {
  --button-opacity: 1;
  --button-inactive-color: gray;
  border-right: var(--border-right);
  border-left: var(--border-left);
  box-shadow: var(--shadow, 2px 2px 5px rgba(91, 142, 175, 0.5), 5px 1px 2px rgb(53, 85, 145));
  background: var(--checked-tool-color, #4697dd);
}

.usual svg {
  font-size: var(--button-icon-size);
  height: var(--button-icon-size);
  width: var(--button-icon-size);
}

.checked_tool {
  box-shadow: inset 2px 2px 5px rgba(154, 147, 140, 0.5), 5px 1px 5px rgb(35, 35, 35);
  background: rgba(var(--checked-tool-color), 0.1);
}
