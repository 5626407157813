.plan_editor {
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}
.popup {
  position: fixed;
  display: none;
  background: white;
  z-index: 9999;
  padding: 4px 8px;
  border-radius: 6px;
  border: 2px solid #5fa2dd;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: black;
  white-space: nowrap;
}

.canvas_wrapper {
  pointer-events: auto;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.pane {
  display: flex;
  justify-content: center;
  background: lightGrey;
  height: calc(100% - 80px); /** 80px - высота верхней панели вкладок */
  width: 100%;
  overflow: hidden;
  padding: 3px;
}

.font_style_container{
  display: flex;
  justify-content: space-evenly;
}

.font_style{
  width: 30px;
  height: 30px;
  text-align: center;
  padding-top: 7px;
  border: solid;
  border-radius: 20%;
  border-color: #e0e0dc;
}

.font_style:hover{
  box-shadow: 1px 2px 3px 0px #52acff;
}

.font_size {
  width: 100%;
}

.font_size div input {
  text-align: center;
}
