.spin {
  height: 100%;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.spin_container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
