.details {
  max-height: 500px;
  padding-top: 20px;
  overflow: auto;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  text-align: start;
}

.buttons_group {
  padding-top: 10px;
}

.details_btn_group {
  padding-left: 10px;
  padding-right: 20px;
}

.save_icon svg {
  font-size: 1.6em;
  padding-bottom: 2px;
}

.save_icon :hover {
  cursor: pointer;
}
