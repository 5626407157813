:root {
  --main-color: #5fa2dd; /** Основной цвет темы */
  --panel-color: #5fa2dd; /** Цвет панелей вверху окна*/
  --checked-tool-color: rgba(0, 0, 0, 0.1); /** Цвет нажатых переключателей меню*/
  --hover-tool-color: rgba(
    255,
    255,
    255,
    0.1
  ); /** Цвет переключателей меню на которые навели указатель*/
}

html {
  overflow: hidden;
  font-family: 'Open Sans', serif;
  font-size: 13px;
  color: #4d4d4d;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  height: 100vh;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: subpixel-antialiased !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
}

body {
  font-size: 13px;
}

a {
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

h1 {
  padding-left: 20px;
}

*,
input,
select,
textarea,
option,
button {
  outline: none !important;
}

.form_item > .ant-form-item-label > label {
  white-space: normal;
  margin-right: 12px;
  display: inline-block;
  text-align: center;
  line-height: 1.3;
}

.lm_content {
  display: flex;
  flex-direction: column;
}

.dragged {
  cursor: copy !important;
}

.ReactVirtualized__List {
  background-color: white;
  overflow-x: auto !important;
  overflow-y: scroll !important;
}

.ReactVirtualized__Table__row:focus {
  outline: none;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.tree-node {
  display: flex;
  flex-direction: column;
  height: 25px !important;
  background-color: white;
  margin: 0 !important;
  cursor: default;
  width: auto !important;
  min-width: 100% !important;
}

.tree-node .tree-node-root {
  background-color: #fffff5 !important;
}

.tree-node > div > div i {
  margin: 0 8px;
}

.tree-node > div > div > span {
  display: flex;
  flex-direction: row;
}

.tree-node-expand-icon {
  padding: 0 17px 0 0;
}

.virtualized-tree {
  background-color: white;
}

.row-hidden {
  display: none;
}

.time-highlighted {
  background-color: rgba(216, 239, 255, 0.3);
}

.row-highlighted {
  background-color: rgb(239, 255, 216, 0.3);
}

.block-title-row-highlighted {
  background-color: rgb(200 237 148 / 50%);
}

.row-unavailable td > * {
  opacity: 0.3;
}

.ReactVirtualized__Table__rowColumn div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper-with-label {
  margin: 12px 8px;
  position: relative;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 8px;
}

.wrapper-label {
  top: -8px;
  font-weight: bold;
  padding: 0 2px;
  background-color: white;
  position: absolute;
}

.expandable__table-with-menu {
  flex: 1;
  height: calc(100% - 35px);
}

.expandable__table-diff {
  display: flex;
}

.expandable__table-diff .cell-content span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.expandable__table-diff .cell-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.expandable__table-diff .tree-node > div > div {
  overflow: hidden;
}

.expandable__table-diff .tree-node > div > div > div {
  overflow: hidden;
}

.selected-row {
  background-color: #daedfb !important;
}

.warning-row {
  background-color: #fcffd0 !important;
}

.disabled-row {
  opacity: 0.7;
  font-style: italic;
}

.grouping-row {
  background-color: #fafafa;
}

.ReactVirtualized__Table__headerRow {
  background-color: lightgray;
  font-weight: normal;
  font-size: 11px !important;
}
.ReactVirtualized__Table__headerColumn {
  display: flex;
  justify-content: center;
  margin: 1px !important;
}
.ReactVirtualized__Table__Grid {
  background-color: white;
}
.ReactVirtualized__Table__rowColumn {
  margin: 0px !important;
}
.ReactVirtualized__Table__row {
  margin: 0 !important;
}

.table__list .ReactVirtualized__Table__row {
  border-bottom: 1px solid lightgray;
}

.table__list .ReactVirtualized__Table__row .ReactVirtualized__Table__rowColumn:not(:first-child) {
  line-height: 26px;
  height: 26px;
  border-left: 1px solid lightgray;
}

.table__list .ReactVirtualized__Table__rowColumn {
  padding: 0 8px;
}

.devices-tree-widget .tree-node > div div:nth-child(3) {
  overflow: hidden;
}

.devices-tree-widget .tree-node > div div:nth-child(3) i {
  margin: 0 4px 0 0 !important;
}

.devices-tree-widget .tree-node > div div:nth-child(3) .cell-content {
  overflow: hidden;
}

.ellipsis-content {
  width: 100%;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
  max-width: 250px;
}

.react-contextmenu.react-contextmenu--visible > div:not(.react-contextmenu-item) {
  padding: 0 !important;
}

.event-cell {
  background: white;
  color: black;
  padding: 0px 8px !important;
  min-height: 25px;
  height: 100%;
}

.activeTool {
  border-bottom: 5px solid white;
}

.activeToolVertical {
  border-right: 5px solid green;
}

/**
 * antd classes
 */

.ant-dropdown-menu {
  max-height: 250px;
  overflow-y: auto;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #000000d9;
  background: #f1cfc7;
  width: 220px;
  cursor: not-allowed;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #000000d9;
  background: #f1cfc7;
  width: 220px;
  cursor: not-allowed;
}

.ant-collapse-content,
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.ant-tabs-bar {
  margin: 0;
}

.ant-tabs-tab {
  padding: 8px !important;
}

.ant-time-picker {
  min-width: 130px !important;
}

.ant-input-number {
  margin-top: 0 !important;
}

.ant-notification-notice {
  word-break: break-word;
}

.ant-transfer-operation .ant-btn .anticon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.ant-tag {
  display: flex;
  align-items: baseline;
  transition: none;
}

/* отключение анимации выделения компонента Select  */
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  transition: none;
}

/* Отключение анимации выделения компонента Input */
.ant-input-number-input {
  transition: none;
}
/* Убираем непонятный отступ */
form textarea.ant-input {
  margin-bottom: 0px;
}

/* Задаём ориентацию спиннера по всей высоте компонента */
.ant-spin-nested-loading {
  height: 100%;
}

/**
 * Классы состояний
 * Все цвета взяты из fireback/fireback/ui-domain-lib-v1/src/main/resources/[language_tag]/stateCategoriesView.xml
 */
.Extinction {
  background: #f87c7c;
  color: #000000;
}
.Fire {
  background: #f87c7c;
  color: #000000;
}
.Fire1 {
  background: #f8ab78;
  color: #000000;
}
.Alarm {
  background: #f87c7c;
  color: #000000;
}
.Warning {
  background: #f8ab78;
  color: #000000;
}
.Malfunction {
  background: #fdfd89;
  color: #000000;
}
.Error {
  background: #fdfd89;
  color: #000000;
}
.AutoOff {
  background: #fdfd89;
  color: #000000;
}
.Ignore {
  background: #cccc88;
  color: #000000;
}
.Unknown {
  background: #888888;
  color: #000000;
}
.Run {
  background: #d9abf0;
  color: #000000;
}
.Info {
  background: #6cf0f0;
  color: #000000;
}
.Service {
  background: #cccc88;
  color: #000000;
}
.OnGuard {
  background: #51a851;
  color: #000000;
}
.OffGuard {
  background: #8b8bff;
  color: #000000;
}
.NotifyOff {
  background: #fdfd89;
  color: #000000;
}
.Normal {
  background: #7cec7c;
  color: #000000;
}
.Null {
  background: #ffffff;
  color: #000000;
}

.ant-table-pagination.ant-pagination {
  margin: 0px;
}

.ant-message .anticon {
  top: -3px !important;
}

.regionSelector > .ant-select-arrow {
  color: #000000;
  font-size: 16px;
}
/**
 * Правила для предпросмотра анимации шейпов устройств
 */

@keyframes BLINKING {
  50% {
    opacity: 0.1;
  }
}

@keyframes RESIZING {
  25% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
}

@keyframes ROTATING {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
