.close_icon {
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
}

/* Убираем стандартную кнопку закрытия уведомления */
.notification_timer :global .ant-notification-notice-close {
  display: none !important;
}

.cpiu_button {
  border-radius: 4px !important;
  margin-top: 10px;
  padding: 0px 5px !important;
  background: #5fa2dd !important;
  box-shadow: 0px 0px 2px 1px #5fa2dd;
}

.cpiu_button:hover {
  background: #d6ecff !important;
  color: #4f4f4f !important;
}

.cpiu_button_icon {
  width: 35px;
  padding-right: 5px;
}

.button_name_container {
  display: flex !important;
}

.button_name_container span {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
