:root{
  --border-right: 3px solid #3f759d;
}
.grid_settings_item {
  display: inline-block;
  height: 36px;
}

.popover {
  display: inline-block;
  position: relative;
  left: 45px;
  bottom: 40px;
  z-index: 10;
}

.popover_content {
  display: flow;
  width: 320px;
  user-select: none;
}

.icon {
  position: relative;
  z-index: 10;
  top: 10px;
  right: 7px;
}

.icon :hover {
  color: white;
}

.triangle {
  position: relative;
  z-index: 9;
  bottom: 11px;
  right: 6px;
  font-size: 0;
  line-height: 0;
  width: 0;
  border-top: 15px solid rgba(255, 255, 255, 0);
  border-left: 15px solid var(--panel-color, #5FA2DD);
  border-bottom: 15px solid rgba(255, 255, 255, 0);
}

.title {
  justify-content: space-between;
  display: flex;
  align-content: center;
  align-items: center;
}

.popout_button {
  position: relative;
}

.column {
  padding-bottom: 5px;
  padding-top: 5px;
}

.font_style_container {
  justify-content: space-evenly;
}

.font_style {
  width: 30px;
  height: 30px;
  text-align: center;
  padding-top: 7px;
  border: 1px solid #e0e0dc;
  border-radius: 15%;
}

.font_style:hover {
  box-shadow: 1px 2px 3px 0px #52acff;
}

.font_size{
  width: 100%; 
}
.font_size div input {
  text-align: center;
}