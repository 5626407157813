.center-aligned {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.center-aligned * {
    font-size: 20px;
}

