.empty {
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 2em;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  background-color: #f1f1f1;
  font-weight: bold;
  font-family: Helvetica, serif;
  text-shadow: 0 1px 0 #ccc, 0 1px 0 #c9c9c9, 0 1px 0 #bbb, 0 1px 0 #b9b9b9, 0 1px 0 #aaa,
    0 1px 1px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.3),
    0 1px 3px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.25), 0 1px 1px rgba(0, 0, 0, 0.2),
    0 1px 1px rgba(0, 0, 0, 0.15);
}
